// Override default variables before the import
//$body-bg: #000;

// Import Bootstrap and its default variables
//@import '~bootstrap/scss/bootstrap.scss';
//

/* simple - enter transition 300ms, exit 150ms */
/* .fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear 150ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
}

.glow-on-hover {
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 50%;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: 0;
    left:0;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 50%;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: -4px;
    border-radius: 50%;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
} */


.form-label-floating .form-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.form-label-floating .form-control-placeholder {
  position: absolute;
  top: 0;
  padding: 7px 0 0 13px;
  transition: all 200ms;
  opacity: 0.5;
  color: #1abc9c;
}

.form-label-floating .form-control:focus + .form-control-placeholder,
.form-label-floating .form-control:valid + .form-control-placeholder {
  padding: 5px 0 3px 0px;
  font-size: 65%;
  transform: translate3d(0, -100%, 0);
  opacity: 1;
}

.client-name {
    color: #bd5d38!important;
    text-transform: capitalize;
}

#section-testimonials {
}
#section-testimonials .item {
    color: #999;
    overflow: hidden;
    min-height: 120px;
    font-size: 13px;
}
#section-testimonials .media {
    position: relative;
    padding: 0 0 0 20px;
}
#section-testimonials .media img {
    width: 75px;
    height: 75px;
    display: block;
    border-radius: 50%;
}
#section-testimonials .testimonial-wrapper {
    padding: 0 10px;
}
#section-testimonials .testimonial {
    color: #808080;
    position: relative;
    padding: 15px;
    background: #f1f1f1;
    border: 1px solid #efefef;
    border-radius: 3px;
    margin-bottom: 15px;
}
#section-testimonials .testimonial::after {
    content: "";
    width: 15px;
    height: 15px;
    display: block;
    background: #f1f1f1;
    border: 1px solid #efefef;
    border-width: 0 0 1px 1px;
    position: absolute;
    bottom: -8px;
    left: 46px;
    transform: rotateZ(-46deg);
}
#section-testimonials .star-rating li {
    padding: 0 2px;
}
#section-testimonials .star-rating i {
    font-size: 16px;
    color: #ffdc12;
}
#section-testimonials .overview {
    padding: 3px 0 0 15px;
}
#section-testimonials .overview .details {
    padding: 5px 0 8px;
}
#section-testimonials .overview b {
    text-transform: uppercase;
    color: #1abc9c;
}
#section-testimonials .carousel-indicators {
    bottom: -70px;
}
/* #section-testimonials .gvZktk, #section-testimonials .dtyeKp { width: 45px; }
#section-testimonials .dtyeKp { right: 0; left: inherit;} */
.carousel-indicators li, .carousel-indicators li.active {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin: 1px 2px;
}
.carousel-indicators li {   
    background: #e2e2e2;
    border: 4px solid #fff;
}
.carousel-indicators li.active {
    color: #fff;
    background: #1abc9c;
    border: 5px double;    
}
.fixed-top {
    z-index: 998;
}
.close {
    color: #dc3545 !important;
    border-radius: 100%;
    /* width: 20px; */
    /* height: 20px; */
    text-align: center;
    line-height: 25px;
    right: 3px;
    position: relative;
    font-size: 45px;
    cursor: pointer;
}
.section-slider .arrow-left {
    background: #fff;
    width: 30px;
    left: 0;
}
.section-slider .arrow-right {
    width: 30px;
    right: 0;
    left: inherit !important;
}
@media (max-width: 992px) {
  #section-testimonials .popup-content {
    width: 90% !important;
  }
}
/* articles */
.card-img-top {
    height: auto;
}
.wp-block-code {
    padding: 16px;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    background-color: #f6f8fa;
    border-radius: 3px;
}
.page-no-banner {
    height: 35px;
    text-align: right;
    border-bottom: 1px solid #BD5E39;
}
.page-no-banner img{ height: 100%; }
/* articles END */
/* Portfolio */
.img-preview-mobile {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 150px;
    border: 1px solid #191919;
}
@media (max-width: 768px) {
  .img-preview-mobile {
    display: none;
  }
}

/* Portfolio END*/
/* MISC */
a:hover {
    opacity: 0.8;
    text-decoration: none;
}
/* MISC END */




/* demo */

#loading{
  position: fixed;
  z-index: 9999999999;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff7a;
  left: 0;
  top: 0;
  cursor: wait;
}
.loading-progress{
  position: absolute;
  top: 0;
  left: 0;
  background: #BD5D38;
  width: 100vw;
  height: 4px;
  animation: loading-progres 1.9s;
}

@-webkit-keyframes loading-progres {
  0% {
    width:0%
  }
  100% {
    width:100%
  }
}
#loading .line-scale-pulse-out span{
  font-size: 20px;
  text-shadow: 0 0 5px #fff, 0 0 10px #fff;
  font-weight: bold;
  letter-spacing: 5px;
  width: 100%;
  display: block;
}
#loading .line-scale-pulse-out > div {
  background-color: #BD5D38;
  width: 4px;
  height: 35px;
  border-radius: 2px;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
  -webkit-animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
  animation: line-scale-pulse-out 0.9s -0.6s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
}
#loading .line-scale-pulse-out > div:nth-child(2), .line-scale-pulse-out > div:nth-child(4) {
  -webkit-animation-delay: -0.4s !important;
  animation-delay: -0.4s !important;
}

#loading .line-scale-pulse-out > div:nth-child(1), .line-scale-pulse-out > div:nth-child(5) {
  -webkit-animation-delay: -0.2s !important;
  animation-delay: -0.2s !important;
}

@-webkit-keyframes line-scale-pulse-out {
  0% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
  }
  50% {
      -webkit-transform: scaley(0.4);
      transform: scaley(0.4);
  }
  100% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
  }
}

@keyframes line-scale-pulse-out {
  0% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
  }
  50% {
      -webkit-transform: scaley(0.4);
      transform: scaley(0.4);
  }
  100% {
      -webkit-transform: scaley(1);
      transform: scaley(1);
  }
}